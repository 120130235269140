import { useCallback } from 'react'
import { apiClient } from 'utils/apiClient'
import { NewBackEndApiResponseData } from 'types/ApiResponseData'
import useSWR from 'swr'

type ResponseType = NewBackEndApiResponseData<{
  list: Array<{
    title: string | null
    content: string
    image: string | null
    url: string | null
  }>
  isVisible: boolean
}>

// fetch user's public custom cards for link-in-bio page
const useFetchingUserCustomCards = (username: string) => {
  const swrKey = `/v2/link-in-bio/public/cards?username=${username}`
  const fetcher = useCallback(async (swrKey: string) => {
    const response = await apiClient.get<ResponseType>(swrKey)
    const { data } = response.data
    return data
  }, [])

  return useSWR(swrKey, fetcher)
}

export default useFetchingUserCustomCards
