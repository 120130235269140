import { memo } from 'react'
import ScrollWrapper from 'shared/components/layout/ScrollWrapper'
import CustomCard from 'shared/components/link-in-bio/custom-cards/CustomCard'
import useFetchingUserCustomCards from 'shared/hooks/data-fetching/useFetchingUserCustomCards'

type ProfileMomentsCarouselProps = {
  username: string
}

const ProfileCustomCards = (props: ProfileMomentsCarouselProps) => {
  const { username } = props
  const { data } = useFetchingUserCustomCards(username)
  const { list: customCards = [], isVisible } = data || {}
  const shouldShowCustomCards = isVisible && customCards.length > 0

  if (!shouldShowCustomCards) return null // user hides custom cards, or has no custom cards
  return (
    <ScrollWrapper autoHide={false} wrapperClassName="full-width-scroll-wrapper w-full">
      <div className="inline-flex gap-4 pb-6">
        {customCards.map((customCard, index) => (
          <div className="w-[275px] flex-none" key={index}>
            <CustomCard
              key={index}
              description={customCard.content}
              label={customCard.title}
              link={customCard.url}
              image={customCard.image}
            />
          </div>
        ))}
      </div>
    </ScrollWrapper>
  )
}

export default memo(ProfileCustomCards)
