import { LinkIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import CustomImage from 'shared/components/CustomImage'
import React, { useCallback, useMemo } from 'react'
import { getValidHrefFromLink } from 'utils/common'

type Props = {
  description: string
  label?: string | null
  link?: string | null
  image?: string | null
}

const CustomCard = (props: Props) => {
  const { description, label, link, image } = props
  const onCustomCardClick = useCallback(() => {
    if (!link) return // do nothing if there is no link
    const validWebsite = getValidHrefFromLink(link)
    validWebsite && window.open(validWebsite, '_blank')
  }, [link])

  const shortenLink = useMemo(() => {
    if (!link) return link // do nothing if there is no link
    try {
      const url = new URL(link || '')
      return url.hostname
    } catch {
      return link
    }
  }, [link])

  return (
    <div
      onClick={onCustomCardClick}
      className={cn(
        'h-full w-full overflow-hidden rounded-[16px] border border-black/10 p-4 px-[16px] shadow',
        !!link && 'cursor-pointer',
      )}
    >
      <div className="flex h-full w-full flex-col gap-2">
        {label && <div className="w-full text-[12px] text-black/60">{label}</div>}
        <p className="w-full whitespace-pre-line text-[12px] font-medium text-kokushoku-black">{description}</p>
        <div className="invisible flex-1" />
        {/* this spaceholder to ensure the link will be placed at the bottom of the card */}
        <div className="inline-flex w-full items-end gap-2 overflow-hidden">
          {/* link */}
          <div className="relative flex-1 overflow-hidden">
            {link && (
              <>
                <LinkIcon className="absolute left-0 top-1/2 h-3 w-3 -translate-y-1/2" />
                <span className="line-clamp-none truncate pl-4 text-[12px] text-kokushoku-black/60">{shortenLink}</span>
              </>
            )}
          </div>
          {/* image */}
          <div className="relative h-[60px] w-[60px] overflow-hidden rounded-lg">
            {image && <CustomImage sizes="120px" src={image} fill className="object-cover object-center" />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomCard
